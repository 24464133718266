import api from '@/base/utils/request'

export const getAiSettings = (data) => {
  return api({
    url: '/admin/ai/settings/getAiSettings',
    method: 'post',
    data,
  })
}

export const setAiSettings = (data) => {
  return api({
    url: '/admin/ai/settings/setAiSettings',
    method: 'post',
    data,
  })
}

