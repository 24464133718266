<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="setting">
    <div class="content" v-loading="loading">
      <el-form
        ref="ruleForm"
        :model="form"
        :rules="rules"
        label-width="140px"
        size="medium"
        class="medium-form"
      >
        <div class="modular">
          <h2 class="modular-title">AI配置</h2>
          <div class="modular-content">
            <el-form-item label="AI开关：" prop="enable">
              <el-switch
                v-model="form.enable"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item>
              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="form.consult_enable"
                >咨询平台客服
                <!-- <el-popover
                  placement="right"
                  title="标题"
                  width="200"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
                >
                  <i class="el-icon-warning-outline" slot="reference"></i>
                </el-popover> -->
              </el-checkbox>

              <el-checkbox
                :true-label="1"
                :false-label="0"
                v-model="form.employ_enable"
                >就业招聘简历填写
                <!-- <el-popover
                  placement="right"
                  title="标题"
                  width="200"
                  trigger="hover"
                  content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。"
                >
                  <i
                    class="el-icon-warning-outline"
                    slot="reference"
                  ></i> </el-popover
              > -->
              </el-checkbox>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <h2 class="modular-title">AI模型API配置</h2>
          <div class="modular-content">
            <el-form-item>
              <el-radio-group v-model="form.api">
                <el-radio label="xinghuo">星火 AI</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="APPID：" prop="xinghuo_appid">
              <el-input v-model="form.xinghuo_appid"></el-input>
            </el-form-item>
            <el-form-item label="APISecret：" prop="xinghuo_secret">
              <el-input v-model="form.xinghuo_secret"></el-input>
            </el-form-item>
            <el-form-item label="APIKey：" prop="xinghuo_key">
              <el-input v-model="form.xinghuo_key"></el-input>
            </el-form-item>
            <el-form-item label="APIUrl：" prop="xinghuo_url">
              <el-input v-model="form.xinghuo_url"></el-input>
            </el-form-item>
            <el-form-item label="APIDomain：" prop="xinghuo_domain">
              <el-input v-model="form.xinghuo_domain"></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="modular">
          <h2 class="modular-title">使用协议</h2>
          <div class="modular-content">
            <el-form-item label="协议开关：" prop="agreement_enable">
              <el-switch
                v-model="form.agreement_enable"
                :active-value="1"
                :inactive-value="0"
              >
              </el-switch>
            </el-form-item>
            <el-form-item label="协议标题：" prop="agreement_title">
              <el-input v-model="form.agreement_title"></el-input>
            </el-form-item>
            <el-form-item label="自定义内容：" prop="agreement_content">
              <el-input
                type="textarea"
                v-model="form.agreement_content"
              ></el-input>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </div>
    <FixedActionBar>
      <el-button
        v-if="!loading"
        type="primary"
        size="medium"
        :loading="saveLoading"
        @click="onSave('ruleForm')"
        >保存</el-button
      >
    </FixedActionBar>
  </div>
</template>

<script>
import ImageItem from '@/modules/common/components/ImageItem.vue'
import FixedActionBar from '@/base/layout/FixedActionBar'
import { getAiSettings, setAiSettings } from '@/modules/ai/api/ai-settings.js'
export default {
  data() {
    return {
      loading: false, //加载中
      form: {
        enable: 0,
        agreement_enable: 0,
        consult_enable: 1,
        employ_enable: 1,
        api: '',
        xinghuo_appid: '',
        xinghuo_key: '',
        xinghuo_secret: '',
        xinghuo_url: '', // Url
        xinghuo_domain: '', // Domain
        agreement_title: '',
        agreement_content: '',
      },
      rules: {
        xinghuo_appid: [{ required: true, message: '请输入', trigger: 'blur' }],
        xinghuo_secret: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
        xinghuo_key: [{ required: true, message: '请输入', trigger: 'blur' }],
        xinghuo_url: [{ required: true, message: '请输入', trigger: 'blur' }],
        xinghuo_domain: [
          { required: true, message: '请输入', trigger: 'blur' },
        ],
      },
      editData: {}, //编辑的数据
      saveLoading: false, //保存中
    }
  },
  methods: {
    //获取详情
    getDetail() {
      this.loading = true
      getAiSettings()
        .then((res) => {
          const { data } = res
          this.editData = data
          this.initForm()

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    //初始化表单
    initForm() {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = this.editData[key]
      })
    },
    //保存
    onSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveLoading = true
          const postData = { config: this.form }
          setAiSettings(postData)
            .then((res) => {
              const { msg } = res
              this.$message.success(msg)

              this.saveLoading = false
            })
            .catch(() => {
              this.saveLoading = false
            })
        }
      })
    },
  },
  created() {
    this.getDetail()
  },
  components: {
    ImageItem,
    FixedActionBar,
  },
}
</script>

<style lang="scss" scoped></style>
../api/consult-content-config
